<template>
  <div>
    <div class="px-3" v-if="!downloading">
      <v-row>
        <v-col cols="12 text-left text-md-right" md="12">
          <small @click="goBack" class="pointer" style="float: left">
            <v-icon>mdi-keyboard-backspace</v-icon>
            Go back
          </small>
        </v-col>
        <v-col cols="12" md="8">
          <small class="grey--text text-left d-block">
            {{ new Date().toDateString() }}
          </small>
          <v-row>
            <v-col cols="12 text-left" md="6">
              <small class="mr-2">Label: </small>
              <v-chip small class="primary lighten-4 primary--text" style="">
                {{ recordDetails.category.name }}
              </v-chip>
            </v-col>
            <v-col cols="12 text-left text-md-right" md="6">
              <v-menu v-if="isUser" top :offset-x="true" left rounded="xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" size="35px" v-bind="attrs" v-on="on">
                    mdi-dots-horizontal-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item
                    dense
                    link
                    v-for="(item, index) in filteredVideoOptions"
                    :key="index"
                    class="text-left"
                  >
                    <div @click="getClickedItem(item)" class="d-inline-flex">
                      <v-list-item-icon class="px-0 mx-0">
                        <v-icon size="18px">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{ item.title }}
                        <v-chip
                          v-if="item.value === 'download_video'"
                          color="darken-2 px-2"
                          x-small
                          >mp4</v-chip
                        >
                      </v-list-item-title>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                text
                small
                class="primary rounded-xl capitalize ml-2"
                @click="copyLink(recordDetails.video_url)"
              >
                <v-icon>mdi-link</v-icon>
                Copy video link
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-1">
            <v-col cols="12 text-left py-0">
              <small class="d-block">
                <v-text-field
                  style="position: absolute; opacity: 0"
                  :value="shareableLink"
                  :id="recordDetails.video_url"
                ></v-text-field>
              </small>
              <span class="font-weight-bold d-block">
                <small>Title: </small> {{ recordTitle }}
                <v-icon
                  size="16"
                  color="primary"
                  title="Edit title"
                  @click="openEditDialog('title')"
                >
                  mdi-pencil-circle
                </v-icon>
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-7">
        <v-col cols="12" md="8">
          <v-card class="videoContainer" flat>
            <video
              ref="video"
              class="video-content video-js vjs-default-skin"
              controlsList="nodownload"
              oncontextmenu="return false"
            ></video>
          </v-card>
          <v-col cols="12" md="12" class="text-left mt-2 mb-10 px-0">
            <span>
              <small>Message: </small>
              {{ recordDetails.message }}
              <v-icon
                size="16"
                color="primary"
                title="Edit note/description"
                @click="openEditDialog('note')"
              >
                mdi-text-box-edit-outline
              </v-icon>
            </span>
          </v-col>
        </v-col>
        <v-col cols="12" md="4">
          <div class="tab-container" color="transparent">
            <v-tabs
              v-model="analyticsTab"
              class="tab-toolbar"
              background-color="transparent"
              fixed
              hide-slider
            >
              <v-tab class="custom-tab" :key="1">Overview</v-tab>
              <v-tab class="custom-tab" :key="2">Activity</v-tab>
              <v-tab class="custom-tab" :key="3">Views</v-tab>
            </v-tabs>

            <v-tabs-items v-model="analyticsTab" class="tabs-content">
              <v-tab-item :key="1" class="tab-item">
                <v-card :elevation="0" class="tabs-item-card">
                  <!-- Call-to-action modal -->
                  <call-to-action-settings
                    v-if="isCallToActionOpen"
                    @toggle-card="toggleCallToActionModal"
                  ></call-to-action-settings>
                  <!-- Audience settings modal -->
                  <audience-settings
                    v-if="isAudienceSettingsOpen"
                    @toggle-card="toggleAudienceSettings"
                  ></audience-settings>
                  <!-- Settings container -->
                  <v-list
                    subheader
                    two-line
                    class="transparent edit-container"
                    v-if="!isCallToActionOpen && !isAudienceSettingsOpen"
                  >
                    <!-- Trim -->
                    <v-list-item
                      class="white mb-2 rounded-xl edit-card"
                      @click="editVideoTrim"
                      elevation="2"
                    >
                      <v-list-item-avatar class="edit-card-icon">
                        <v-icon class="secondary edit-card-icon-2" dark
                          >mdi-content-cut</v-icon
                        >
                      </v-list-item-avatar>
                      <v-list-item-content class="text-left edit-card-body">
                        <v-list-item-title class="edit-card-title"
                          >Edit Your Video</v-list-item-title
                        >
                        <v-list-item-subtitle class="edit-card-subtitle"
                          >Trim, split or add clip</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="lighten-1">mdi-chevron-right</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- Call-to-action -->
                    <v-list-item
                      class="white mb-2 rounded-xl edit-card"
                      @click="toggleCallToActionModal"
                      elevation="2"
                    >
                      <v-list-item-avatar class="edit-card-icon">
                        <v-icon class="secondary edit-card-icon-2" dark
                          >mdi-link-variant</v-icon
                        >
                      </v-list-item-avatar>
                      <v-list-item-content class="text-left edit-card-body">
                        <v-list-item-title class="edit-card-title"
                          >Call-to-Action</v-list-item-title
                        >
                        <v-list-item-subtitle class="edit-card-subtitle"
                          >Add CTA to your video page</v-list-item-subtitle
                        >
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="lighten-1">mdi-chevron-right</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- Audience settings -->
                    <v-list-item
                      class="white mb-2 rounded-xl edit-card"
                      @click="toggleAudienceSettings"
                      elevation="2"
                    >
                      <v-list-item-avatar class="edit-card-icon">
                        <v-icon class="secondary edit-card-icon-2" dark
                          >mdi-cog</v-icon
                        >
                      </v-list-item-avatar>
                      <v-list-item-content class="text-left edit-card-body">
                        <v-list-item-title class="edit-card-title"
                          >Audience Setting</v-list-item-title
                        >
                        <v-list-item-subtitle class="edit-card-subtitle"
                          >Enhancement and viewers permission
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color="lighten-1">mdi-chevron-right</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <!-- Platform overview -->
                  <v-card
                    class="white rounded-xl pa-2 text-left"
                    v-if="!isCallToActionOpen && !isAudienceSettingsOpen"
                    flat
                  >
                    <div class="rounded-xl secondary pa-2 px-3 mb-2">
                      <p class="my-0 grey--text">Recorded By</p>
                      <strong class="py-0">
                        <small>{{ recordDetails.user.name }}</small>
                      </strong>
                      <small class="primary--text">
                        ({{ recordDetails.user.email }})
                      </small>
                    </div>
                    <div class="rounded-xl secondary pa-2 px-3 mb-2">
                      <p class="my-0 grey--text">Platform</p>
                      <strong class="py-0">
                        <small>
                          {{
                            recordDetails.platform
                              ? recordDetails.platform
                              : "Windows 10.0 / Chrome 97.0 4692.99"
                          }}
                        </small>
                      </strong>
                    </div>
                    <div class="rounded-xl secondary pa-2 px-3 mb-2">
                      <p class="my-0 grey--text">Video Quality</p>
                      <strong class="py-0">
                        <small>{{ recordDetails.quality }}</small>
                      </strong>
                    </div>
                    <div class="rounded-xl secondary pa-2 px-3 mb-2">
                      <p class="my-0 grey--text">Requested</p>
                      <strong class="py-0">
                        <small>
                          {{
                            recordDetails["requested"] === "yes"
                              ? "Yes"
                              : "Not request, submitted via public form " +
                                recordDetails["created_at"]
                          }}
                        </small>
                      </strong>
                    </div>
                  </v-card>
                </v-card>
              </v-tab-item>

              <v-tab-item :key="2" class="tab-item">
                <v-card :elevation="0" class="tabs-item-card">
                  <!-- folder toolbar -->
                  <p>Activity</p>
                </v-card>
              </v-tab-item>

              <v-tab-item :key="3" class="tab-item">
                <v-card :elevation="0" class="tabs-item-card">
                  <!-- folder toolbar -->
                  <p>Views</p>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="400">
        <v-card class="" flat>
          <v-toolbar class="error--text" flat>
            <v-spacer></v-spacer>
            <v-card-title>Warning!!!</v-card-title>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = !dialog" icon>
              <v-icon size="15" color="error">mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="text-left d-inline-block">
            <v-card-text class="py-0">
              You don't have enough subscription minutes to view this recording,
              kindly subscribe or upgrade your plan.
            </v-card-text>
            <v-card-subtitle class="pb-0">
              Plan minutes left:
              <strong>{{ user["minutes_left"] || 0 }} minutes</strong>
            </v-card-subtitle>
            <v-card-subtitle class="py-0">
              Video duration:
              <strong>
                {{ recordminutes.toFixed(2) }}
                {{ recordminutes >= 2 ? "minutes" : "minute" }}
              </strong>
            </v-card-subtitle>
          </div>
          <v-card-actions>
            <v-btn
              class="capitalize rounded-xl mt-2"
              color="primary"
              to="/account-upgrade"
              block
            >
              Upgrade now
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <snackbar
        :color="snackBarColor"
        :snackBarText="snackBarText"
        :snackbar="snackbar"
      />
      <edit-recording-dialog
        ref="editDialog"
        :type="type"
      ></edit-recording-dialog>
      <video-settings :dialog="video_settings_dialog"></video-settings>
      <embed-code
        :dialog="embed_code_dialog"
        :video_title="recordTitle"
        :video_url="recordDetails.video_url"
      ></embed-code>
      <subtitles :dialog="subtitle_dialog"></subtitles>
      <interaction-tool :dialog="interaction_tool_dialog"></interaction-tool>
    </div>
    <div v-show="downloading">
      <download-progress
        ref="download_video"
        :recordDetails="recordDetails"
      ></download-progress>
    </div>
    <div v-show="trimming">
      <Trim ref="trim_recording" :recordDetails="recordDetails" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import EditRecordingDialog from "./EditRecordingDialog";
import EmbedCode from "./EmbedCode";
import InteractionTool from "./InterationTool.vue";
import Subtitles from "./Subtitles";
import VideoSettings from "./VideoSettings";
import DownloadProgress from "../DownloadProgress";
import CallToActionSettings from "../Settings/CallToActionSettings.vue";
import AudienceSettings from "../Settings/AudienceSettings.vue";
import Trim from "../Settings/Trim.vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default {
  components: {
    EditRecordingDialog,
    VideoSettings,
    EmbedCode,
    Subtitles,
    InteractionTool,
    DownloadProgress,
    CallToActionSettings,
    AudienceSettings,
    Trim,
  },
  data() {
    return {
      video: null,
      duration: null,
      dialog: false,
      deducted: false,
      type: null,
      snackbar: false,
      snackBarText: "",
      snackBarColor: "",
      video_settings_dialog: false,
      embed_code_dialog: false,
      subtitle_dialog: false,
      interaction_tool_dialog: false,
      downloading: false,
      trimming: false,
      status: 0,
      progress: 0,
      videoOptions: [
        {
          icon: "mdi-format-list-bulleted",
          title: "video settings",
          value: "video_settings",
          dialog_name: "video_settings_dialog",
        },
        {
          icon: "mdi-tray-arrow-down",
          title: "Download video",
          value: "download_video",
        },
        {
          icon: "mdi-xml",
          title: "Embed video",
          value: "embed_video",
          dialog_name: "embed_code_dialog",
        },
        {
          icon: "mdi-closed-caption-outline",
          title: "Subtitle",
          value: "subtitle",
          dialog_name: "subtitle_dialog",
        },
        {
          icon: "mdi-video-check-outline",
          title: "Interaction tool",
          value: "interaction_tool",
          dialog_name: "interaction_tool_dialog",
        },
      ],
      analyticsTab: null,
      isCallToActionOpen: false,
      isAudienceSettingsOpen: false,
    };
  },
  mounted() {
    this.initPlayer();
    if (this.user.roles !== "admin") {
      this.video = document.querySelector("video");
      let details = this.recordDetails;
      let minutes_left = this.user["minutes_left"];
      this.duration = details.duration / 60;
      let beneficiary = this.user["beneficiaries"].find(
        (x) => x.user["minutes_left"] >= this.duration
      );
      if ((this.video && this.duration) || !this.duration) {
        this.video.onplay = () => {
          if (
            this.user.id === details["received_by"] &&
            details["viewed"] === "no"
          ) {
            if (
              (!beneficiary && !minutes_left) ||
              (minutes_left && minutes_left < this.duration && !beneficiary)
            ) {
              this.dialog = true;
              this.video.pause();
            } else if (
              !minutes_left ||
              (minutes_left < this.duration && beneficiary)
            ) {
              if (!this.deducted) {
                this.updateRecordingAsViewed(details);
              }
            } else {
              if (!this.deducted) {
                this.updateRecordingAsViewed(details);
              }
            }
          }
        };
      } else {
        this.video.pause();
      }
    }
  },
  computed: {
    ...mapGetters("recordings", ["recordDetails"]),
    ...mapGetters("auth", ["user"]),
    recordminutes() {
      return this.recordDetails.duration / 60;
    },
    shareableLink() {
      let link = "";
      link =
        "http://" +
        this.recordDetails.domain +
        "/share/" +
        this.recordDetails.id;
      return link;
    },
    recordTitle() {
      const title = this.recordDetails.title;
      return title.length > 70 ? title.substr(0, 70) + "..." : title;
    },
    isUser() {
      const user = this.user;
      return !!(
        !user["active_user"] || user["active_user"]["roles"] !== "viewer"
      );
    },
    filteredVideoOptions() {
      const user = this.user;
      const videoOptions = this.videoOptions;
      if (user?.["active_user"]?.["roles"] === "admin") return videoOptions;
      if (user?.["active_user"]?.["roles"] === "manager")
        return videoOptions.filter((x) => x.value !== "interaction_tool");
      return videoOptions;
    },
  },
  methods: {
    ...mapActions({
      download_video: "recordings/download_video",
    }),
    initPlayer() {
      const options = {
        controls: true,
        autoplay: false,
        preload: "auto",
        fluid: true,
        aspectRatio: "15:8",
        sources: [
          {
            src: this.recordDetails.video_url,
            type: "video/webm",
          },
        ],
      };
      // Create Video.js player
      videojs(this.$refs.video, options);
    },
    editVideoTrim() {
      this.$refs.trim_recording.openDialog();
    },
    toggleCallToActionModal() {
      this.isCallToActionOpen = !this.isCallToActionOpen;
    },
    toggleAudienceSettings() {
      this.isAudienceSettingsOpen = !this.isAudienceSettingsOpen;
    },
    openEditDialog(type) {
      // this.type = recordDetails;
      this.type = type;
      this.$refs.editDialog.openDialog(this.recordDetails);
    },
    goBack() {
      this.deducted = false;
      this.$router.go(-1);
    },
    async updateRecordingAsViewed(details) {
      try {
        const response = await this.$axios.post(
          "/recordings/" + details.id + "/viewed"
        );
        this.$store.commit("auth/USER_DATA", response.data.user, {
          root: true,
        });
        this.deducted = true;
      } catch (e) {
        console.log(e);
      }
    },
    copyLink(id) {
      const link = document.getElementById(id);
      link.select();
      document.execCommand("copy");
      this.snackBarText = "video link copied";
      this.snackbar = !this.snackbar;
      this.snackBarColor = "primary";
      setTimeout(() => {
        this.snackbar = false;
      }, 2000);
    },
    async getClickedItem(item) {
      this[item.dialog_name] = true;
      if (item.value === "download_video") {
        await this.downloadVideo();
      }
    },

    async downloadVideo() {
      const video_settings = this.recordDetails.video_settings;
      if (
        video_settings == null ||
        video_settings["allow_download"] !== "yes"
      ) {
        this.$response.staticError(
          "Video download is not enabled, please enable and try again"
        );
      } else {
        this.downloading = true;
        await this.$refs.download_video.processDownload();
      }
    },
  },
};
</script>

<style scoped>
/** Video content */
.videoContainer {
  height: 70vh !important;
  width: 100%;
  overflow: hidden;
  border: none !important;
  border-radius: 10px !important;
  background-color: transparent !important;
}
.video-content video {
  width: 100%;
  background-color: #222 !important;
  border-radius: 10px !important;
}
/** + */
/* .edit-container {
} */
.edit-card {
  padding: 0px 7px !important;
  max-height: 60px !important;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
}
.edit-card:hover {
  background-color: #dadada !important;
}
.edit-card-icon {
  height: 30px !important;
  width: 30px !important;
  margin-right: 5px !important;
}
.edit-card-icon-2 {
  font-size: 14px !important;
  color: #000 !important;
}
.edit-card-body {
  padding: 0px !important;
}
.edit-card-title {
  font-size: 16px !important;
  font-weight: 500;
}
.edit-card-subtitle {
  font-size: 13px !important;
}
/* Tab styles */
.tab-container {
  padding: 0px;
}
.tab-toolbar {
  margin-top: -55px;
}
.tabs-content {
  margin-top: 4px;
  background-color: transparent !important;
  border-bottom: none !important;
}
.tabs-item-card {
  border: none;
  background-color: transparent !important;
}
.custom-tab {
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}
</style>
