<template>
  <div>
    <div
      class="flex w-full h-screen items-center justify-center text-center"
      id="app"
    >
      <img
        v-if="logo_url || temp_logo"
        :src="temp_logo || logo_url"
        alt=""
        height="50"
      />
      <div
        class="secondary pa-4 rounded-xl dotted"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          multiple
          name="fields[assetsFieldHandle][]"
          id="assetsFieldHandle"
          class="w-px h-px hide overflow-hidden absolute"
          @change="onChange"
          ref="file"
          accept=".jpg,.jpeg,.png"
        />

        <label
          ref="selectImage"
          for="assetsFieldHandle"
          class="block cursor-pointer"
        >
          <small style="font-size: 11px">
            Upload 842 x 292 design for better display
          </small>
          <small class="d-block"> Drag and Drop image here or </small>
          <v-btn
            @click="$refs['selectImage'].click()"
            class="capitalize primary px-5 mt-2"
            rounded
            text
            small
            :loading="loading"
          >
            {{ logo_url ? "Change Logo" : "select image" }}
          </v-btn>
          <v-btn
            v-if="logo_url || temp_logo"
            @click="removeLogo"
            class="capitalize secondary darken-1 black--text ml-2 px-5 mt-2"
            rounded
            text
            small
            :loading="removeLoading"
          >
            Remove
          </v-btn>
        </label>
        <ul class="mt-4" v-if="filelist.length && show" v-cloak>
          <li
            class="text-sm p-1"
            v-for="(file, index) in filelist"
            :key="index"
          >
            {{ file.name }}
            <button
              class="ml-2"
              type="button"
              @click="remove(filelist.indexOf(file))"
              title="Remove file"
            >
              <v-icon color="error" size="17">mdi-delete-outline</v-icon>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ResponseHelper from "../utils/ResponseHelper";
export default {
  props: {
    user_id: String,
    logo_url: String,
  },
  name: "CtaDragAndDrop",
  data() {
    return {
      filelist: [],
      loading: false,
      show: false,
      toast: new ResponseHelper(),
      temp_logo: null,
      removeLoading: false,
    };
  },
  methods: {
    ...mapActions("settings", ["settings_update"]),
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.loading = true;
      (async () => {
        try {
          this.loading = false;
          this.temp_logo = null;
          this.toast.sendSuccess({
            success: true,
            message: "Successfully!",
            code: 200,
          });
        } catch (e) {
          this.loading = false;
          this.toast.sendError(e);
        }
      })();
    },
    async removeLogo() {
      this.removeLoading = true;
      // const data = { logo: true };
      try {
        this.removeLoading = false;
        this.temp_logo = null;
        this.toast.sendSuccess({
          success: true,
          message: "Removed!",
          code: 200,
        });
      } catch (e) {
        this.removeLoading = false;
        this.toast.sendError(e);
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}
.hide {
  opacity: 0;
  position: absolute;
}
/** + */
.dotted {
  border: 2px dashed #6257fe !important;
}
</style>
