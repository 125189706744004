<template>
  <div>
    <v-card class="mb-3 px-5 pa-7 rounded-xl" :elevation="0">
      <div
        class="text-left call-to-action-left-btn"
        @click="toggleCallToActionModal"
      >
        <v-icon class="call-to-action-left-icon">mdi-chevron-left</v-icon>
        Call-to-action
      </div>
      <v-form @submit.prevent="saveCallToActionDetails" class="mt-3">
        <div class="input-group">
          <label for="">Button link (URL)</label>
          <v-text-field
            class="input-field"
            v-model="form.button_link"
            type="url"
            required
            solo
            dense
            flat
            outlined
            rounded
          ></v-text-field>
        </div>
        <div class="input-group">
          <label for="">Button text</label>
          <v-text-field
            class="input-field"
            v-model="form.button_text"
            type="text"
            required
            solo
            dense
            flat
            outlined
            rounded
          ></v-text-field>
        </div>
        <div class="input-group">
          <label for="">Button color</label>
          <v-text-field
            class="input-field"
            v-model="form.button_color"
            type="color"
            required
            solo
            dense
            flat
            outlined
            rounded
          ></v-text-field>
        </div>
        <div class="input-group">
          <label for="">Text color</label>
          <v-text-field
            class="input-field"
            v-model="form.text_color"
            type="color"
            required
            solo
            dense
            flat
            outlined
            rounded
          ></v-text-field>
        </div>
        <div class="input-group">
          <label for="">Button corner style</label>
          <v-select
            :items="buttonCornerStyleList"
            item-text="name"
            item-value="key"
            v-model="form.button_corner_style"
            class="input-field"
            required
            solo
            dense
            flat
            outlined
            rounded
          >
            <template v-slot:item="{ item }">
              <div class="text-center">{{ item.name }}</div>
            </template>
          </v-select>
        </div>
        <div class="input-group">
          <label for="">Logo</label>
          <div class="rounded-xl dotted-border">
            <cta-drag-and-drop
              user_id="null"
              logo_url="null"
            ></cta-drag-and-drop>
          </div>
        </div>
        <template>
          <v-btn
            block
            depressed
            color="primary"
            class="rounded-xl text-capitalize"
            type="submit"
          >
            Save</v-btn
          >
        </template>
      </v-form>
    </v-card>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
import CtaDragAndDrop from "../CtaDragAndDrop.vue";
export default {
  name: "CallToActionSettings",
  props: {},
  components: {
    CtaDragAndDrop,
  },
  data() {
    return {
      loading: false,
      toast: new ResponseHelper(),
      form: {},
      buttonCornerStyleList: [
        { key: "rounded", name: "Rounded" },
        { key: "squared", name: "Squared" },
        { key: "srounded", name: "Slightly Rounded" },
      ],
    };
  },
  methods: {
    toggleCallToActionModal() {
      this.$emit("toggle-card");
    },
    saveCallToActionDetails() {},
  },
};
</script>

<style scoped>
/** Call to action styles */
.call-to-action-left-btn {
  font-weight: 600;
  cursor: pointer;
}
.call-to-action-left-btn:hover {
  color: blue !important;
}
.call-to-action-left-icon {
  margin-top: -4px;
  margin-right: -4px;
}

/** Input style */
.input-group {
  text-align: left;
  margin-bottom: 12px;
}
.input-group > label {
  font-size: 14px;
}
.input-group > .input-field {
  height: 42px !important;
}
</style>
