<template>
  <div>
    <v-card class="mb-3 px-5 pa-7 rounded-xl" :elevation="0">
      <div
        class="text-left call-to-action-left-btn"
        @click="toggleAudienceSettings"
      >
        <v-icon class="call-to-action-left-icon">mdi-chevron-left</v-icon>
        Audience settings
      </div>
      <v-form @submit.prevent="saveAudienceSettingsDetails" class="mt-3">
        <v-list subheader two-line class="transparent">
          <!-- 1. Allow comments -->
          <v-list-item class="white mb-2 rounded-xl" elevation="2">
            <v-list-item-content class="text-left">
              <v-list-item-title>Comments</v-list-item-title>
              <v-list-item-subtitle class="edit-card-subtitle">
                Allow viewers to add comment
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="audience_settings.allow_comments"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <!-- 2. Allow comments notifications -->
          <v-list-item class="white mb-2 rounded-xl" elevation="2">
            <v-list-item-content class="text-left">
              <v-list-item-title>Comments notification</v-list-item-title>
              <v-list-item-subtitle class="edit-card-subtitle">
                Get an email when someone comments
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                v-model="audience_settings.allow_notifications"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
          <!-- 3. Allow emoji reactions -->
          <v-list-item class="white mb-2 rounded-xl" elevation="2">
            <v-list-item-content class="text-left">
              <v-list-item-title>Emoji reactions</v-list-item-title>
              <v-list-item-subtitle class="edit-card-subtitle">
                Allow viewers to add reactions
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="audience_settings.allow_reactions"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <!-- 4. Allow download -->
          <v-list-item class="white mb-2 rounded-xl" elevation="2">
            <v-list-item-content class="text-left">
              <v-list-item-title>Download</v-list-item-title>
              <v-list-item-subtitle class="edit-card-subtitle">
                Allow viewers to download your video
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="audience_settings.allow_downloads"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <!-- 5. Allow Call to action -->
          <v-list-item class="white mb-2 rounded-xl" elevation="2">
            <v-list-item-content class="text-left">
              <v-list-item-title>Call-to-action</v-list-item-title>
              <v-list-item-subtitle class="edit-card-subtitle">
                Allow viewers to see call-to-action
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="audience_settings.allow_cta"></v-switch>
            </v-list-item-action>
          </v-list-item>
          <!-- 6. Allow video analytics -->
          <v-list-item class="white mb-2 rounded-xl" elevation="2">
            <v-list-item-content class="text-left">
              <v-list-item-title>Analytics</v-list-item-title>
              <v-list-item-subtitle class="edit-card-subtitle">
                Allow viewers to see video analytics
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="audience_settings.allow_analytics"></v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-row>
          <v-col cols="6" md="6" lg="6">
            <template>
              <v-btn
                block
                depressed
                class="rounded-xl text-capitalize"
                @click="setDefaultAudienceSettings"
              >
                Set default</v-btn
              >
            </template>
          </v-col>
          <v-col cols="6" md="6" lg="6">
            <template>
              <v-btn
                block
                depressed
                color="primary"
                class="rounded-xl text-capitalize"
                type="submit"
              >
                Save</v-btn
              >
            </template>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
// import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  name: "AudienceSettings",
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      toast: new ResponseHelper(),
      audience_settings: {
        allow_comments: false,
        allow_notifications: false,
        allow_reactions: true,
        allow_downloads: false,
        allow_cta: true,
        allow_analytics: true,
      },
    };
  },
  methods: {
    toggleAudienceSettings() {
      this.$emit("toggle-card");
    },
    saveAudienceSettingsDetails() {
      console.log("Result:", this.audience_settings);
    },
    setDefaultAudienceSettings() {},
  },
};
</script>

<style scoped>
/** Call to action styles */
.call-to-action-left-btn {
  font-weight: 600;
  cursor: pointer;
}
.call-to-action-left-btn:hover {
  color: blue !important;
}
.call-to-action-left-icon {
  margin-top: -4px;
  margin-right: -4px;
}
/* ./ */
.edit-card-subtitle {
  font-size: 13px !important;
}
</style>
