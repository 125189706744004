<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay persistent>
        <v-card elevation="0">
          <v-toolbar color="white" elevation="0" class="top-nav">
            <v-btn icon @click="closeTrimEditor">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <v-img
                max-height="60"
                max-width="120"
                :src="require('@/assets/logo.png')"
              ></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="nav-list">
              <v-btn
                @click="closeTrimEditor"
                class="nav-btn"
                elevation="0"
                color="secondary"
              >
                Cancel
              </v-btn>
            </v-toolbar-items>
            <v-toolbar-items class="nav-list">
              <v-btn
                @click="revertToOriginal"
                :disabled="undoDisabled && redoDisabled"
                class="nav-btn"
                elevation="1"
                color="secondary"
              >
                Revert to original
              </v-btn>
            </v-toolbar-items>
            <v-toolbar-items class="nav-list">
              <v-btn
                @click="saveTrimmedVideo"
                :disabled="undoDisabled && redoDisabled"
                :loading="loading"
                class="nav-btn-2"
                elevation="1"
                color="primary"
              >
                <v-icon class="nav-icon"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card class="white videoContainer" flat>
            <video
              ref="video"
              class="video-content video-js vjs-default-skin"
              controlsList="nodownload"
              :src="recordDetails.video_url"
              controls
              :start="trimLeft"
              :end="trimRight"
              @timeupdate="handleTimeUpdate"
              oncontextmenu="return false"
            ></video>
          </v-card>
          <v-divider></v-divider>
          <v-toolbar color="white" elevation="0" class="top-nav">
            <v-toolbar-items class="left-tool-btn-group">
              <v-btn
                class="tool-btn left-tool-btn bordered"
                elevation="0"
                color="white"
                disabled
                @click="splitTimeFrame"
              >
                <v-icon class="nav-icon">mdi-compare</v-icon>
                Split at {{ formatTime(trimLeft) }}
              </v-btn>
              <v-btn
                class="tool-btn left-tool-btn"
                elevation="0"
                color="secondary"
                disabled
              >
                <v-icon class="nav-icon">mdi-delete-circle</v-icon>
                Delete clip
              </v-btn>
              <div class="vertical-line-2"></div>
              <v-btn
                class="tool-btn left-tool-btn bordered"
                elevation="0"
                color="white"
                disabled
              >
                <v-icon class="nav-icon">mdi-plus</v-icon>
                Add clip
              </v-btn>
              <v-btn
                class="tool-btn left-tool-btn bordered"
                elevation="0"
                color="white"
                disabled
              >
                <v-icon class="nav-icon">mdi-water-off</v-icon>
                Blur
              </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items class="nav-list">
              <div class="recording-duration">
                <v-icon>mdi-clock-outline</v-icon>
                {{ formatTime(trimLeft) }} &#8594; {{ formatTime(trimRight) }}
              </div>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-toolbar-items class="tool-revert-box">
              <v-btn
                class="tool-btn"
                elevation="1"
                color="white"
                @click="undo"
                :disabled="undoDisabled"
              >
                <v-icon class="nav-icon">mdi-undo</v-icon>
                Undo
              </v-btn>
              <div class="vertical-line"></div>
              <v-btn
                class="tool-btn"
                elevation="1"
                color="white"
                @click="redo"
                :disabled="redoDisabled"
              >
                <v-icon class="nav-icon">mdi-redo</v-icon>
                Redo
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-toolbar elevation="0">
            <v-col cols="12" class="trim-editor-bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                @mousemove="handleDrag"
                @mouseup="stopDrag"
                @mouseleave="stopDrag"
                width="1200"
                height="50"
                class="trim-svg"
              >
                <!-- Trim bar -->
                <rect
                  :x="timeToPixels(trimLeft)"
                  y="10"
                  ry="4"
                  rx="4"
                  :width="timeToPixels(trimWidth)"
                  height="45"
                  fill="#FFF"
                  style="stroke-width: 3; stroke: blue"
                />
                <!-- Left and Right drag handles -->
                <rect
                  :x="timeToPixels(trimLeft) + 10"
                  y="5"
                  ry="1"
                  rx="1"
                  width="5"
                  height="55"
                  fill="#FFF"
                  @mousedown="startDragLeft"
                  style="cursor: ew-resize; stroke-width: 3; stroke: blue"
                />
                <rect
                  :x="timeToPixels(trimRight) - 20"
                  y="5"
                  ry="1"
                  rx="1"
                  width="5"
                  height="55"
                  fill="#FFF"
                  @mousedown="startDragRight"
                  style="cursor: ew-resize; stroke-width: 3; stroke: blue"
                />
                <!-- Vertical stroke representing current playback position -->
                <line
                  x1="0"
                  y1="3"
                  x2="0"
                  y2="62"
                  stroke="red"
                  stroke-width="3"
                  ref="playbackLine"
                />
              </svg>
            </v-col>
          </v-toolbar>
          <div class="trim-info-container">
            <p>
              Trim your video by dragging the handles and using the Split
              button. <br />
              <v-icon class="trim-info-icon">mdi-information-outline</v-icon>
              <a href="#" class="trim-info-link" target="_blank"
                >Learn more about splitting.</a
              >
            </p>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";

export default {
  name: "Trim",
  props: {
    recordDetails: Object,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      toast: new ResponseHelper(),
      trimLeft: 0,
      trimRight: this.recordDetails.duration, // total video duration
      isDraggingLeft: false,
      isDraggingRight: false,
      dragStartX: 0,
      videoDuration: this.recordDetails.duration, // total video duration
      // isVideoPlaying: true,
      undoStack: [],
      redoStack: [],
    };
  },
  methods: {
    ...mapActions("recordings", ["my_recording_details", "trim_recording"]),

    openDialog() {
      this.dialog = true;
    },
    closeTrimEditor() {
      this.$refs.video.currentTime = 0;
      this.$refs.video.pause();
      this.dragStartX = 0;
      this.videoDuration = this.recordDetails.duration;
      this.trimLeft = 0;
      this.trimRight = this.recordDetails.duration;
      this.undoStack = [];
      this.redoStack = [];
      this.dialog = false;
    },
    async saveTrimmedVideo() {
      this.loading = true;
      const form = {
        id: this.recordDetails.id,
        start_time: this.trimLeft,
        end_time: this.trimRight,
      };

      try {
        const response = await this.trim_recording(form);
        // this.$store.commit("recordings/SHOW_DETAILS")
        this.toast.sendSuccess(response);
        this.loading = false;
        this.closeTrimEditor();
      } catch (e) {
        this.toast.sendError(e);
        this.loading = false;
      }
    },
    revertToOriginal() {
      this.$refs.video.currentTime = 0;
      this.$refs.video.pause();
      this.dragStartX = 0;
      this.isDraggingLeft = false;
      this.trimLeft = 0;
      this.trimRight = this.recordDetails.duration;
      this.videoDuration = this.recordDetails.duration;
      this.undoStack = [];
      this.redoStack = [];
    },
    // Manage State
    saveState(trimLeft, trimRight) {
      // Save the current state to the undo stack
      this.undoStack.push({
        trimLeft,
        trimRight,
      });
      // Clear the redo stack since a new state is added
      this.redoStack = [];
    },
    undo() {
      if (this.undoStack.length > 0) {
        // Pop the last state from the undo stack
        const prevState = this.undoStack.pop();
        // Update the trimLeft and trimRight values
        this.trimLeft = prevState.trimLeft;
        this.trimRight = prevState.trimRight;
        // Push the undone state to the redo stack
        this.redoStack.push(prevState);
      }
    },
    redo() {
      if (this.redoStack.length > 0) {
        // Pop the last state from the redo stack
        const nextState = this.redoStack.pop();
        // Update the trimLeft and trimRight values
        this.trimLeft = nextState.trimLeft;
        this.trimRight = nextState.trimRight;
        // Push the redone state to the undo stack
        this.undoStack.push(nextState);
      }
    },
    // ./manage-state
    timeToPixels(time) {
      return (time / this.videoDuration) * 1200; // 1200 is the width of the SVG
    },
    pixelsToTime(pixels) {
      return (pixels / 1200) * this.videoDuration;
    },
    startDragLeft(e) {
      this.isDraggingLeft = true;
      this.dragStartX = e.clientX;
    },
    startDragRight(e) {
      this.isDraggingRight = true;
      this.dragStartX = e.clientX;
    },
    handleDrag(e) {
      if (this.isDraggingLeft) {
        let newTrimLeft = this.pixelsToTime(
          this.timeToPixels(this.trimLeft) + e.clientX - this.dragStartX
        );
        newTrimLeft = Math.max(0, Math.min(newTrimLeft, this.trimRight - 1)); // Ensure trimLeft is within range
        this.trimLeft = newTrimLeft;
        this.dragStartX = e.clientX;
        this.$refs.video.currentTime = this.trimLeft;
        this.saveState(newTrimLeft, this.trimRight); // change current state
      } else if (this.isDraggingRight) {
        let newTrimRight = this.pixelsToTime(
          this.timeToPixels(this.trimRight) + e.clientX - this.dragStartX
        );
        newTrimRight = Math.min(
          this.videoDuration,
          Math.max(newTrimRight, this.trimLeft + 1)
        ); // Ensure trimRight is within range
        this.trimRight = newTrimRight;
        this.dragStartX = e.clientX;
        this.$refs.video.currentTime = this.trimLeft;
        this.saveState(this.trimLeft, newTrimRight); // change current state
      }
    },
    stopDrag() {
      this.isDraggingLeft = false;
      this.isDraggingRight = false;
    },
    formatTime(time) {
      const hours = Math.floor(time / 3600);
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    },
    handleTimeUpdate() {
      const currentTime = this.$refs.video.currentTime;
      if (currentTime < this.trimLeft || currentTime > this.trimRight) {
        this.$refs.video.pause();
      }
      // Update the position of the playback line
      const playbackLine = this.$refs.playbackLine;
      if (playbackLine) {
        playbackLine.setAttribute("x1", this.timeToPixels(currentTime) + "px");
        playbackLine.setAttribute("x2", this.timeToPixels(currentTime) + "px");
      }
    },
    splitTimeFrame() {},
  },
  watch: {
    trimLeft() {
      this.$refs.video.currentTime = this.trimLeft;
    },
    trimRight() {
      this.$refs.video.currentTime = this.trimLeft;
    },
  },
  computed: {
    trimWidth() {
      return this.trimRight - this.trimLeft;
    },
    playbackPositionPixel() {
      return this.$refs.video.currentTime;
    },
    undoDisabled() {
      return this.undoStack.length === 0;
    },
    redoDisabled() {
      return this.redoStack.length === 0;
    },
  },
  // mounted() {},
  // beforeDestroy() {},
};
</script>

<style scoped>
/** Video content */
.videoContainer {
  height: 480px !important;
  width: 100%;
  overflow: hidden;
  border: none !important;
  border-radius: 0px !important;
}
.video-content {
  width: 100%;
  height: 480px !important;
  background-color: #222 !important;
}
/** Top Navigation bar */
.top-nav {
  /* border: 1px solid red !important; */
  padding-top: 2px !important;
  padding-bottom: 5px !important;
  height: 70px !important;
}
.nav-list {
  height: 35px;
  margin-right: 25px;
}
.nav-btn {
  text-transform: capitalize;
  border-radius: 40px !important;
  color: #000 !important;
}
.nav-btn-2 {
  text-transform: capitalize;
  border-radius: 40px !important;
}
.nav-icon {
  font-size: 18px;
  margin-right: 3px;
}
/* Trim tool */
.tool-revert-box {
  border: 1px solid #aaa;
  border-radius: 40px !important;
  padding: 3px;
  height: 35px;
}
.tool-btn {
  text-transform: capitalize;
  border-radius: 40px !important;
  color: #666 !important;
}
.vertical-line {
  border-left: 1px solid #aaa;
  margin: 4px;
}
.vertical-line-2 {
  border-left: 1px solid #aaa;
  margin-right: 18px;
}
.left-tool-btn {
  margin-right: 15px;
}
.bordered {
  border: 1px solid #ddd !important;
}
.left-tool-btn-group {
  height: 42px;
  padding: 3px;
}
.recording-duration {
  color: #000;
  font-weight: 600;
}
/** Trim Editor */
.trim-editor-bar {
  /* border: 1px solid red !important; */
  height: 90px;
  position: relative;
}
.trim-svg {
  border: 1px solid #ddd !important;
  border-radius: 10px;
  height: 65px !important;
}
.trim-info-container {
  margin-top: 20px;
}
.trim-info-link {
  text-decoration: none;
}
.trim-info-icon {
  font-size: 18px !important;
  margin-bottom: 3px;
  margin-right: 4px;
}
</style>
